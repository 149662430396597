import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"

const NotFoundPage = () => {
  return (
    <Layout>
    <PageHeader title="Page not found" breadcrumbs="Home /  Page not found" />
      <main>
          <div className={'mx-auto max-w-screen-2xl w-full relative z-0'}>
          <h1>Page not found</h1>
          <p>
            Sorry 😔, we couldn’t find what you were looking for.
            <br />
            <Link to="/">Go home</Link>.
          </p>
        </div>
      </main>
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
